<template> 
	<v-select class="custom-vue-select" :options="paginated" :filterable="false" @open="onOpen" @close="onClose" v-model="select" select return-object
		@search="(query) => (search = query)" @input="setValue">
		<template #list-footer>
			<li v-show="hasNextPage" ref="load" class="loader">
				Loading more options...
			</li>
		</template>
	</v-select>
</template>

<script>
export default {
	name: 'SelectDropdown',
	props: ['items', 'id'],
	data: () => ({
		observer: null,
		limit: 10,
		search: '',
        select: null,
	}),
	computed: {
		filtered() {
			return this.items.filter((item) => item.label.toLowerCase().includes(this.search.toLowerCase()))
		},
		paginated() {
			return this.filtered.slice(0, this.limit)
		},
		hasNextPage() {
			return this.paginated.length < this.filtered.length
		},
        matterNumber() {
            return this.items.find(item => item.id == this.id);
        },
	},
	mounted() {
		/**
		 * You could do this directly in data(), but since these docs
		 * are server side rendered, IntersectionObserver doesn't exist
		 * in that environment, so we need to do it in mounted() instead.
		 */
		this.observer = new IntersectionObserver(this.infiniteScroll)
	},
	created() {
		setTimeout(() => {
            if (this.id) {
                this.setValue(this.id);
            }
		}, 1000);
	},
	methods: {
		async onOpen() {
			if (this.hasNextPage) {
				await this.$nextTick()
				this.observer.observe(this.$refs.load)
			}
		},
		onClose() {
			this.observer.disconnect()
		},
		async infiniteScroll([{ isIntersecting, target }]) {
			if (isIntersecting) {
				const ul = target.offsetParent
				const scrollTop = target.offsetParent.scrollTop
				this.limit += 10
				await this.$nextTick()
				ul.scrollTop = scrollTop
			}
		},
		setValue(value) {
			this.$emit('setSelected', value);
		},
	},
    watch: {
        matterNumber: {
            immediate: true,
            handler(value) {
                this.select = value || 0;
            }
        }
    },
}
</script>

<style scoped>
.loader {
	text-align: center;
	color: #bbbbbb;
}
</style>

<style>
.custom-vue-select .vs__dropdown-toggle {
    height: 40px !important; /* Set your desired height and use !important */
	border-radius: 0px !important;
}
</style>