import Vue from 'vue';
import Vuex from 'vuex';
import config from '@/config';
import configuration from './modules/configuration';
import country from './modules/country';
import shipmentTracking from './modules/shipment-tracking';
import courierServices from './modules/courierServices';

Vue.use(Vuex);

const store = new Vuex.Store({
	state: {
		config,
	},
	mutations: {},
	actions: {},
	modules: {
		configuration,
        country,
        shipmentTracking,
		courierServices,
	},
})

export default store;