import _get from 'lodash/get'
import http from '../../http';

const initialState = () => ({
	internationalShipmentTrackingList: {},
})

export default {
	namespaced: true,
	state: initialState(),
	getters: {
		internationalShipmentTrackingList: (state) => state.internationalShipmentTrackingList,
	},
	mutations: {
		SET_TRACKING_LIST: (state, { trackingData }) => {
			state.internationalShipmentTrackingList = trackingData;
		},
	},
	actions: {
		setTrackingList: async ({ commit }, id) => {
			try {
				const response = await http.get(`/api/shipment-tracking/${id}/get`);
                const trackingList = _get(response, 'data.result');
                commit('SET_TRACKING_LIST', { trackingList });

                return trackingList;
			} catch (error) {
				throw error;
			}
		},
	},
}