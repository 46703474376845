import _get from 'lodash/get'
import http from '../../http';

const initialState = () => ({
	courierServices: {},
})

export default {
	namespaced: true,
	state: initialState(),
	getters: {
		courierServices: (state) => state.courierServices,
	},
	mutations: {
		SET_COURIERS: (state, { courierServices }) => {
			state.courierServices = courierServices;
		},
	},
	actions: {
		setCourierProviders: async ({ commit }, type) => {
			try {
				const response = await http.get(`/api/provider/get-providers-by-service-type/${type}`);
				const courierServices = _get(response, 'data.providers'); 
				commit('SET_COURIERS', { courierServices });
	
				return courierServices;
			} catch (error) {
				throw error;
			}
		},
		setAvailableCourierProviders: async ({ commit }, payload) => {
			try {
				const response = await http.get(`/api/provider/get-providers-by-state?pickup_state=${payload.pickup_state}&delivery_state=${payload.delivery_state}`);
				const courierServices = _get(response, 'data.providers'); 
				commit('SET_COURIERS', { courierServices });
	
				return courierServices;
			} catch (error) {
				throw error;
			}
		},
	}
}